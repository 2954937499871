<template>
  <div>

    <QUERY @selDis="selDis" ref="QUERY"></QUERY>
    <div>
      <Footer @setPages="setPages" msg="right" ref="footPagesR"></Footer>
    </div>
    <div class="goodsList" v-show="goodsList.length > 0">

      <div class="goodsList_div" v-for="(item, index) in goodsList" :key="index">
        <div class="goodsList_div_imgdiv">

          <div class="goodsList_div_imgdiv_icon">
            <img v-for="(fname,index) in item.iconArr" height="30" :src="fname" :key="index">
          </div>

          <div class="goodsList_div_imgdiv_goodsImg">
            <img :src="`http://hwimg.xmvogue.com/thumb/${item.goods_code}.jpg?x-oss-process=style/440`"
                 style="width: 318px;height: 318px;" @click="clickImg(item)" />
          </div>

        </div>
        <div class="goodsList_div_bottom">
          <div class="prod_title">
            <span style="color: #000000; font-size: 14px; margin-right: 5px">{{ item.goods_code }}</span>
            <i class="el-icon-document-copy" v-clipboard:copy="item.goods_code" v-clipboard:success="onCopy"
               v-clipboard:error="onError"></i>
          </div>
          <div class="prod_title">
            <el-tooltip :content="item.GoodsName" placement="bottom" effect="light">
              <p style="color: #000000; font-size: 15px; margin-right: 5px">
                {{ item.GoodsName | cutstr }}
              </p>
            </el-tooltip>
          </div>
          <div v-if="userInfo.pid === 0 || userInfo.show_price === 1">
          <div class="prod_title">
            <!-- style="text-decoration:line-through" -->
            <div style="font-size: 14px; color: #000000;text-decoration:line-through">
              {{ $t("PURCHASE PRICE") }}:
            </div>
            <div style="font-size: 14px; color: #d92524; margin-left: 5px;text-decoration:line-through">
              {{ $t("Price symbol") }}{{ item.base_price.toFixed(2) }}
            </div>

          </div>
          <div class="prod_title">
            <div style="font-size: 14px; color: #000000; margin-left: 5px">
              {{ $t("RETAIL PRICE") }}:
            </div>
            <div style="font-size: 14px; color: #d92524; margin-left: 5px">
              {{ $t("Price symbol") }}{{ item.sale_price.toFixed(2) }}
            </div>
          </div>
          <!-- 折扣价,-->
            <template v-if="parseInt(current_dis_data.dis_type)===2 || disListKeyVal[parseInt(item.dis_id)]===2" >
                <div class="prod_title" style="text-decoration:line-through">
                  <!-- style="text-decoration:line-through" -->
                  <div style="font-size: 14px; color: #000000;">{{ $t("Discount Price") }}:</div>
                  <div style="font-size: 14px; color: #d92524;">
                    ￥{{ item.dis_price.toFixed(2) }}
                  </div>
                </div>
            </template>
            <template v-else-if="disListKeyVal[parseInt(item.dis_id)]===3">
              <!-- 不显示折扣价，只显示惊爆价  -->
              <!--  -->
              <div class="prod_title">
                <!-- style="text-decoration:line-through" -->
                <div style="font-size: 14px; color: #000000;">{{ $t("Discount Price") }}:</div>
                <div style="font-size: 14px; color: #d92524;">
                  ￥{{ item.fixed_price.toFixed(2) }}
                </div>
              </div>
            </template>
            <template v-else>
              <div class="prod_title">
                <!-- style="text-decoration:line-through" -->
                <div style="font-size: 14px; color: #000000;">{{ $t("Discount Price") }}:</div>
                <div style="font-size: 14px; color: #d92524;">
                  ￥{{ item.dis_price.toFixed(2) }}
                </div>
              </div>
            </template>
          <!-- Explosive Price 惊爆价-->
          <div class="prod_title"
               v-if="parseInt(current_dis_data.dis_type)===2 || disListKeyVal[parseInt(item.dis_id)]===2">
            <!-- style="text-decoration:line-through" -->
            <img v-if="lang === 'zh-CN'" :src="require('@/assets/images/dis/bigsellCN.png')" alt="" style="width:60px">
            <img v-if="lang === 'en'" :src="require('@/assets/images/dis/bigsale.png')" alt="" style="width:60px">
            <div style="font-size: 14px; color: #000000;">{{ $t("Explosive Price") }}:</div>
            <div style="font-size: 14px; color: #d92524;">
              ￥{{ item.fixed_price.toFixed(2) }}
            </div>
          </div>
          <!-- Explosive Price 惊爆价-->
          </div>
          <div class="change_num">
            <number-input :numberValue.sync="item.addNum" :total="item['us_num']"
                          :min="item.purchase_spec" :step="item.purchase_spec" @updateNumber="updateNumber"
            />
          </div>
          <div class="prod_handle">
            <div class="prod_handle_add">
              <p class="prod_handle_title">
                <a @click="addCarts(item)">{{ $t("Add to Cart") }}</a>
                <a style="margin-left: 8px">[{{ item.user_cart_info.num }}]</a>
                <i class="el-icon-delete" style="margin-left: 8px"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="notFindGoods" v-show="goodsList.length === 0">
      {{ $t("No products of this type found") }}
    </div>
    <div>
      <Footer @setPages="setPages" ref="footPagesB"></Footer>
    </div>
  </div>
</template>
<script>
import { GetDisGoodsList } from "@/api/goods/goods.js";
import common from "@/common/mixins/common.js";
import { AddToCartByGoods } from "@/api/carts/carts.js";
import Footer from "@/views/web/allGoods/footer/index.vue";
import QUERY from "@/views/web/marketing/content/query.vue";
import NumberInput from "@/components/NumberInput.vue";

export default {
  mixins: [common],
  components: {
    NumberInput,
    Footer,
    QUERY,
  },
  data() {
    return {
      num: [],
      goodsList: [],
      typeList: [],
      queryData: {
        dis_id:0,
        page: 1,
        pageSize: 16,
        GtClsNo:"",
        type_field:"",
        priceBegin:"",
        priceEnd:"",
        Display:"y"
      },
      currentItem: {},
      current_dis_data: {}, //当前点击的分类信息
      disListKeyVal:{},
      lang: "",
      userInfo:{},
      currentDisId:0,
    };
  },
  watch: {},
  created() {
    this.lang=window.sessionStorage.getItem("lg") || "en";
    this.getData();
    setTimeout(() => {
      this.disListKeyVal = this.$refs.QUERY.disListKeyVal
    }, 500);
    const user = window.sessionStorage.getItem("webUser");
    const userArr = JSON.parse(user);
    this.userInfo = userArr.user_info;
  },
  methods: {
    //复制商品条码
    onCopy: function() {
      this.$message.success("Product barcode copied to clipboard");
    },
    onError: function() {
      this.$message.success("Copy failed");
    },
    //设置活动选项
    selDis(queryData,current_dis_data) {
      this.current_dis_data=current_dis_data;
      this.queryData.dis_id=this.current_dis_data.id;
      this.queryData.page=1;
      this.queryData.GtClsNo=queryData.GtClsNo;
      this.queryData.type_field = queryData.type_field
      this.queryData.priceBegin = queryData.priceBegin
      this.queryData.priceEnd = queryData.priceEnd
      this.queryData.Display = queryData.Display || 'y'
      this.getData();
    },
    //加入购物车
    addCarts(row) {
      if ((row.addNum % row.purchase_spec) != 0) {
        this.$message.error(this.$t("Please change in multiples of specifications"));
        this.$nextTick(() => {
          row.addNum=row.purchase_spec;
        });
      } else {
        AddToCartByGoods(row.goods_code, row.addNum, row.purchase_spec).then(() => {
          this.$emit("initCart");
          this.getData();
        });
      }

      // AddToCartByGoods(row.goods_code, row.addNum, 0).then(() => {
      // 	this.$emit("initCart");
      // });
    },
    //设置翻页
    setPages(page) {
      this.queryData.page=page;
      this.getData();
    },
    //获取列表产品
    getData() {
      let queryData=this.queryData;
      queryData.page=this.queryData.page;
      queryData.pageSize=this.queryData.pageSize;
      GetDisGoodsList(queryData).then((res) => {
        let resData=res.data;

        //设置分页
        this.$refs.footPagesB.setPages(
          resData.data.page,
          resData.data.total,
          resData.data.page_size,
        );
        this.$refs.footPagesR.setPages(
          resData.data.page,
          resData.data.total,
          resData.data.page_size,
        );

        let filterData=this.filterGoods(resData.data.data);
        this.goodsList=filterData;
      }).catch(() => {
        this.$message.error("Unable to find product"); //无法找到产品
      });
    },
    //设定一个添加购物车的数据，用来绑定添加购物车的数量
    filterGoods(goodsData) {
      let resData=goodsData;
      for (let i=0; i < resData.length; i++) {
        resData[i].addNum=resData[i].purchase_spec;
        resData[i].iconArr=resData[i].file_type_icon_list.split(",");
        //语言判断
        if (this.$i18n.locale == "zh-CN") {
          resData[i].GoodsName=resData[i].item_name;
        } else {
          resData[i].GoodsName=resData[i].item_en;
        }
      }
      return resData;
    },

    handleChange(value) {

    },
    //跳转到详情页
    clickImg(row) {
      let {
        href,
      }=this.$router.resolve({
        path: "/web/goodsDetail",
        query: {
          id: row.goods_code,
        },
      });
      window.open(href);
    },
    updateNumber(val) {
      this.currentItem.addNum=val;
    },
  },
};
</script>
<style lang="scss" scoped>
.goodsList {
  width: 1400px;
  margin: 0 auto;
  background-color: #f5f8fa;
  display: grid;
  grid-template-columns: 320px 320px 320px 320px;
  grid-row-gap: 32px;
  grid-column-gap: 32px;

  &_div {
    // background: yellow;
    border: 1px solid #ccc;

    &_imgdiv {
      min-height: 320px;
      position: relative;

      //图标
      &_icon {
        position: absolute;
        top: 2px;
        right: 2px;

        img {
          border: 1px solid #ccc;
          margin-left: 3px;
        }
      }

      //商品图标
      &_goodsImg {
        cursor: pointer;
        text-align: center;
        border-bottom: 1px solid #ccc;
        // min-height: 320px;
        width: 318px;
        height: 319px;
      }
    }

    //文字按钮样式
    &_bottom {
      text-align: center;
      margin-top: 5px;
    }

  }
}

.prod_title {
  width: 320px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.change_num {
  //width: 356px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prod_handle {
  //width: 356px;
  height: 40px;
  background-color: #ddedf0;
  margin-top: 5px;

  &_add {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  &_title {
    color: #218da0;
    font-size: 16px;
    border-bottom: 1px solid #218da0;
  }
}

.notFindGoods {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
}
</style>
