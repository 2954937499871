<template>
  <div style="width: 100%;">
    <div class="goodsDetail_header_inner" style="margin-top: 20px;height: 40px;display: flex;align-items: center;">
      
      <div style="font-size: 18px; width: 1400px; margin: 0 auto; color: #515a6e">
        <i class="el-icon-s-home"></i>{{ $t("Home") }} >{{ $t("Special Offers") }}
      </div>
    </div>
    <!--  -->
    <Header @selPrice="selPrice" @selQueryData="selQueryData"></Header>
    <!--  -->
    <ul class="disQueryUl">
      <li class="title">{{ $t("Special Offers") }}:</li>
        <template v-for="(item, index) in disList">
          <li class="disli" @click="toGoods(item)" :style="current_dis_data.id===item.id?activeStyle:''">
            <template v-if="item.is_tip_update==1 && Math.trunc(Date.now()/1000) >= item.tip_update_start_time && Math.trunc(Date.now()/1000) <= item.tip_update_end_time">
              <el-badge value="new" class="item">{{ item.name  }}</el-badge>
            </template>
            <template v-else>
              {{ item.name }}
            </template>
          </li>
        </template>
    </ul>

    <div style="width: 1400px; margin: 0 auto;">{{ $t("Discounted products do not enjoy") }}</div>
  </div>
</template>

<script>
import { getDisList } from "@/api/goods/goods.js";
import Header from "@/views/web/marketing/content/header.vue";

export default {
  components: {
    Header,
  },
  data() {
    return {
      disList: [],
      disListKeyVal :{},
      activeStyle:{
        "color": "#fff",
        "background-color": "#238da5"
      },
      current_dis_data: {}, //当前点击的分类信息
      queryData: {
        page: 1,
        pageSize: 16,
        GtClsNo:"",
        type_field:"",
        priceBegin:"",
        priceEnd:"",
      },
    };
  },
   created() {
        this.getData();
  },
    methods: {
    //设置
    selQueryData(obj) {
      this.queryData.GtClsNo=obj.cls_no;
      this.queryData.page=1;
      this.queryData.type_field = obj.type_field
      this.toGoods(this.current_dis_data);
    },
    // 选择价格
    //设置价格 begin
    selPrice(val) {
      switch (val.key) {
        case "all":
          this.queryData.priceBegin="";
          this.queryData.priceEnd="";
          break;
        case "1":
          this.queryData.priceBegin="0";
          this.queryData.priceEnd="10";
          break;
        case "2":
          this.queryData.priceBegin="11";
          this.queryData.priceEnd="20";
          break;
        case "3":
          this.queryData.priceBegin="21";
          this.queryData.priceEnd="40";
          break;
        case "4":
          this.queryData.priceBegin="41";
          this.queryData.priceEnd="60";
          break;
        case "5":
          this.queryData.priceBegin="60";
          this.queryData.priceEnd="1000";
          break;
      }
      this.toGoods(this.current_dis_data);
    },
    // 
    getData() {
      getDisList().then((res) => {
        let resDataList = res.data.data;
        let disList = [];
        disList.push({
          id: "",
          name: "ALL",
        });
        for (let i = 0; i < resDataList.length; i++) {
          disList.push(resDataList[i]);
          this.disListKeyVal[resDataList[i].id]=resDataList[i].dis_type;
        }

        this.disList = disList;
      });
    },
    toGoods(disInfo) {
      this.current_dis_data = disInfo //设置当前选中
      this.$emit("selDis", this.queryData,this.current_dis_data);
    },
  },
};
</script>

<style scoped>
.disQueryUl {
  display: flex;
  /* border: 1px solid #238DA5; */
  width: 1400px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 30px;
  line-height: 30px;
}

.disQueryUl .title {
  font-weight: bold;
  font-size: 18px;
  padding-left: 0;
}

.disQueryUl li {
  font-size: 14px;
  padding: 0 10px;
}

.disQueryUl .disli {
  /* border-right: 1px solid #238DA5; */
  min-width: 120px;
  text-align: center;
  color: #515a6e;
  font-size: 18px;
}

.disQueryUl .disli:hover {
  color: white;
  background: #238da5;
  cursor: pointer;
}
</style>
